<!--
 * @Description: 审核售后单
 * @Author: 琢磨先生
 * @Date: 2022-09-02 05:24:37
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2022-10-23 01:45:40
-->
<template>
  <el-button type="primary" link size="small" @click="onOpen">审核</el-button>
  <el-dialog
    title="售后单审核"
    v-model="visible"
    width="1000px"
    draggable
    :append-to-body="true"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
  >
    <div v-loading="loading">
      <el-descriptions title="订单详情">
        <el-descriptions-item label="单号">{{model.order.order_no}}</el-descriptions-item>
        <el-descriptions-item label="商品">{{model.order.goods_title}}</el-descriptions-item>
        <el-descriptions-item label="规格">{{model.order?.sku?.spec_values}}</el-descriptions-item>
        <el-descriptions-item label="数量">x{{ model.order.number }}</el-descriptions-item>
        <el-descriptions-item label="订单状态">{{ model.order.status_text }}</el-descriptions-item>
        <el-descriptions-item label="快递" :width="300">
          <span>{{model.order.waybill_no}}</span>
          <el-popover placement="bottom" :width="600" v-if="model.order.is_deliver">
            <template #reference>
              <el-link :underline="false" type="primary">物流信息</el-link>
            </template>
            <div style="height:400px;">
              <el-scrollbar>
                <div class="waybill" v-for="item in model.order.waybill_list" :key="item.id">
                  <div class="no">
                    <b>{{item.company_name}}、{{item.waybill_no}}</b>
                  </div>
                  <el-alert type="info" :closable="false" title>
                    <el-timeline v-if="item.track_list.length>0">
                      <el-timeline-item
                        v-for="(activity, index) in item.track_list"
                        :key="index"
                        :timestamp="activity.ftime"
                      >{{ activity.context }}</el-timeline-item>
                    </el-timeline>
                    <div v-else>
                      <span>暂无物流信息</span>
                    </div>
                  </el-alert>
                </div>
              </el-scrollbar>
            </div>
          </el-popover>
        </el-descriptions-item>
        <el-descriptions-item label="商品金额">￥{{ model.order.decimal_amount }}</el-descriptions-item>
        <el-descriptions-item label="收件地址">{{ model.order.consignee }} {{ model.order.consignee_mobile_phone }} {{ model.order.consignee_address }}</el-descriptions-item>
        <el-descriptions-item label="下单时间">{{ model.order.create_at }}</el-descriptions-item>
      </el-descriptions>
      <el-descriptions title="售后申请">
        <el-descriptions-item label="类型">{{model.type_text}}</el-descriptions-item>
        <el-descriptions-item label="状态">{{model.status_text}}</el-descriptions-item>
        <el-descriptions-item label="退款金额">￥{{model.decimal_amount}}</el-descriptions-item>
        <el-descriptions-item label="原因">{{model.cause}}</el-descriptions-item>
        <el-descriptions-item label="备注说明">{{model.remark??''}}</el-descriptions-item>
        <el-descriptions-item label="创建时间">{{model.create_at}}</el-descriptions-item>
      </el-descriptions>
      <el-divider>附件</el-divider>
      <div>
        <el-row>
          <el-image
            style="width: 100px; height: 100px"
            :src="url"
            v-for="(url) in model.pic_urls"
            :key="url"
            :preview-src-list="model.pic_urls"
            fit="cover"
          ></el-image>
        </el-row>
      </div>
    </div>

    <el-alert type="info" :closable="false" title="审核结果" style="margin-top:20px;">
      <el-form :model="form" ref="form" :rules="rules" label-width="100px">
        <el-form-item label="结果">
          <el-radio-group v-model="form.check_result">
            <el-radio :label="5">同意</el-radio>
            <el-radio :label="10">拒绝</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item
          label="退款金额"
          prop="decimal_amount"
          v-if="(model.type==5 || model.type==10) && form.check_result==5"
        >
          <el-col :span="10">
            <el-input v-model="form.decimal_amount" placeholder></el-input>
          </el-col>
        </el-form-item>
        <el-form-item label="需要退货" v-if="model.order.is_deliver && form.check_result==5">
          <el-radio-group v-model="form.is_rollback">
            <!-- <el-checkbox :label="true">是</el-checkbox> -->
            <el-radio :label="true">是</el-radio>
            <el-radio :label="false">否</el-radio>
          </el-radio-group>
        </el-form-item>

        <el-form-item label="备注信息">
          <el-input
            v-model="form.check_remark"
            placeholder
            type="textarea"
            style="width:500px;"
            :rows="5"
          ></el-input>
        </el-form-item>
      </el-form>
      <b v-if="model.order.is_deliver && form.check_result==5">订单已发货、同意退货退款 请注意跟踪原包裹物流状态~</b>
    </el-alert>
    <template #footer>
      <el-button type="primary" @click="onSubmit">确定</el-button>
    </template>
  </el-dialog>

</template>

<script>

export default {
  components: {
  },
  data() {
    return {
      loading: false,
      visible: false,
      saving: false,
      model: {
        order: {
          sku: {},
        },
      },
      form: {
        check_result: 5,
        is_rollback: false,
      },
      rules: {
        check_remark: [{ required: true, message: "请输入", trigger: "blur" }],
      },

    };
  },
  props: ["id"],
  emits: ["close"],
  created() {},
  methods: {
    /**
     *
     */
    onOpen() {
      this.visible = true;
      this.loadDtl();
    },
    /**
     *
     */
    loadDtl() {
      this.loading = true;
      this.$http
        .get("seller/v1/mallRefund/dtl?id=" + this.id)
        .then((res) => {
          if (res.code == 0) {
            this.model = res.data;
            this.form.decimal_amount = res.data.decimal_amount;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },

    /**
     * 提交保存
     */
    onSubmit() {
      this.form.id = this.id;
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.saving = true;
          this.$http
            .post("seller/v1/mallRefund/check", this.form)
            .then((res) => {
              if (res.code == 0) {
                this.visible = false;
                this.$emit("close", true);
              }
            });
        }
      });
    },
  },
};
</script>

<style scoped>
</style>